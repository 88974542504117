export const mixinIsLoading = {
  data: () => ({
    isLoading: false
  }),
  methods: {
    startLoading (value = true) {
      this.isLoading = value
    },

    finishLoading () {
      this.isLoading = false
    }
  }
}
